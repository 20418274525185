import i18n from '@/i18n';

const productsSubsections = [
  {
    label: i18n.t('products.documentation'),
    to: { name: 'ProductTab', params: { subsection: 'documentation' } },
  },
  {
    label: i18n.t('products.forms'),
    to: { name: 'ProductTab', params: { subsection: 'forms' } },
  },
  {
    label: i18n.t('products.archive'),
    to: { name: 'ProductTab', params: { subsection: 'archive' } },
  },
];

export default productsSubsections;
