<template>
  <div>
    <Tabs :tabs="subsections"></Tabs>
    <div class="items">
      <div class="items__heading">{{ $t('products.productFiles') }}</div>
      <ArchiveFileRow
        v-if="$route.params.subsection === 'archive'"
        :items="items"
        :isEmpty="isEmpty">
      </ArchiveFileRow>
      <DownloadFileRow
        v-else
        :items="items"
        :isEmpty="isEmpty">
      </DownloadFileRow>
    </div>
  </div>
</template>

<script>
import productsSubsections from '@/config/productsSubsections';
import { mapGetters } from 'vuex';
import Tabs from '@/components/Tabs.vue';
import DownloadFileRow from '@/components/DownloadFileRow.vue';
import ArchiveFileRow from '@/components/ArchiveFileRow.vue';

export default {
  name: 'ProductTab',
  props: ['item'],
  data() {
    return {
      subsections: productsSubsections,
      items: [],
    };
  },
  components: {
    Tabs,
    DownloadFileRow,
    ArchiveFileRow,
  },
  created() {
    this.setItems(this.$route.params.subsection);
  },
  beforeRouteUpdate(to, from, next) {
    this.setItems(to.params.subsection);
    next();
  },
  computed: {
    ...mapGetters('options', ['currentOptions']),
    isEmpty() {
      return (this.items.length === 0);
    },
  },
  methods: {
    setItems(subsection) {
      if (subsection !== 'forms') {
        this.items = this.item.acf[subsection] || [];
      } else {
        this.items = this.currentOptions.products_forms;
      }
    },
  },
};
</script>
